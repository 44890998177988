export const portfolioData = [
  // {
  //   id: 1,
  //   name: "zoheeb-golf.com",
  //   languages: ["spring-boot", "bootstrap"],
  //   languagesIcons: [
  //     "fab fa-java",
  //     "fab fa-js",
  //     "fab fa-bootstrap",
  //     "fab fa-css3-alt",
  //   ],
  //   source: "https://github.com/zoheebishaq/project-golf-gretaV2",
  //   info: "Cette application est le projet de fin de formation réalisé pour l’obtention du titre professionnel de Concepteur Développeur d'application. L'application a pour thème les tournois de golf . Le projet est hébergé sur un serveur HEROKU, les temps de chargement sont un peu longs au début. ",
  //   login: "invite",
  //   mdp: "invite",
  //   picture: "./media/projet5.png",
  //   online: "https://zoheeb-golf.herokuapp.com/",
  // },
  // {
  //   id: 2,
  //   name: "prestigecab.com",
  //   languages: ["spring-boot", "bootstrap"],
  //   languagesIcons: [
  //     "fab fa-java",
  //     "fab fa-js",
  //     "fab fa-bootstrap",
  //     "fab fa-css3-alt",
  //   ],
  //   source: "https://github.com/zoheebishaq/prestige_cab",
  //   info: "Cette application est le projet de fin de formation réalisé pour l’obtention du titre professionnel de Développeur web et web mobile. L'application a pour thème la vente de voiture. Le projet est hébergé sur un serveur HEROKU, les temps de chargement sont un peu longs au début. ",
  //   login: "invite",
  //   mdp: "invite",
  //   picture: "./media/projet7.png",
  //   online: "https://prestigecab-zoheeb.herokuapp.com/",
  // },
  {
    id: 3,
    name: "zoheeb-todo.com",
    languages: ["spring-boot", "bootstrap"],
    languagesIcons: ["fab fa-java", "fab fa-bootstrap", "fab fa-css3-alt"],
    source: "https://github.com/zoheebishaq/todo-management-spring-boot",
    info: "Projet de todo-list, réalisé à partir d'un tuto sur internet dans le cadre de l'apprentissage du framework spring boot. Le projet est hébergé sur un serveur HEROKU, les temps de chargement sont un peu longs au début.",
    login: "admin",
    mdp: "admin",
    picture: "./media/projet3.png",
    online: "https://zoheeb-todo.herokuapp.com/",
  },
  {
    id: 4,
    name: "zoheeb-employe.com",
    languages: ["spring-boot", "bootstrap"],
    languagesIcons: [
      "fab fa-java",
      "fab fa-js",
      "fab fa-bootstrap",
      "fab fa-css3-alt",
    ],
    source: "https://github.com/zoheebishaq/employe",
    info: "Projet solo sur le thème de la gestion des salariés, ce projet à étais réalisé dans le cadre de l'apprentissage du framework spring boot. Le projet est hébergé sur un serveur HEROKU, les temps de chargement sont un peu longs au début.",
    login: "aucun",
    mdp: "aucun",
    picture: "./media/projet2.png",
    online: "https://zoheeb-employe.herokuapp.com/",
  },
  {
    id: 5,
    name: "zoheeb-react-world.com",
    languages: ["javascript", "react"],
    languagesIcons: [
      "fab fa-js",
      "fab fa-css3-alt",
      "fab fa-sass",
      "fab fa-react",
    ],
    source: "https://github.com/zoheebishaq/react-world",
    info: "Projet réalisé dans le cadre de l'apprentissage du framework react.js. Ce projet consomme une API qui donne des informations sur tous les pays du monde.",
    picture: "./media/project4.png",
    login: "aucun",
    mdp: "aucun",
    online: "https://zoheeb-react-world.herokuapp.com/",
  },
  {
    id: 6,
    name: "zoheeb-crypto-app.com",
    languages: ["javascript", "react"],
    languagesIcons: ["fab fa-js", "fab fa-css3-alt", "fab fa-react"],
    source: "https://github.com/zoheebishaq/crypto-app",
    info: "Projet réalisé dans le cadre de l'apprentissage du framework react.js. Ce projet consomme une API qui donne des informations sur les cryptomonnaies.",
    picture: "./media/projet6.png",
    login: "aucun",
    mdp: "aucun",
    online: "https://zoheeb-crypto-app.herokuapp.com/",
  },
  {
    id: 7,
    name: "zoheeb-user-api.com",
    languages: ["spring-boot"],
    languagesIcons: ["fab fa-java"],
    source: "https://github.com/zoheebishaq/sb-first-api",
    info: "Projet d'API réalisé dans le cadre de l'apprentissage du framework spring boot. Le projet est hébergé sur un serveur HEROKU, les temps de chargement sont un peu longs au début.",
    login: "aucun",
    mdp: "aucun",
    picture: "./media/projet8.png",
    online: "https://zoheeb-user-api.herokuapp.com/api/users",
  },
  {
    id: 8,
    name: "zoheeb-cour-api.com",
    languages: ["spring-boot"],
    languagesIcons: ["fab fa-java"],
    source: "https://github.com/zoheebishaq/cours-api-rest",
    info: "Projet d'API réalisé dans le cadre de l'apprentissage du framework spring boot. Cette application est une amélioration de ma première API. Cette API est consommée par une autre application qui est dans le portfolio ''zoheeb-react-cour''. Ce projet est hébergé sur un serveur HEROKU, les temps de chargement sont un peu longs au début.",
    login: "aucun",
    mdp: "aucun",
    picture: "./media/projet10.png",
    online: "https://zoheeb-cour-api.herokuapp.com/api/courses",
  },
  {
    id: 9,
    name: "zoheeb-poudlard.com",
    languages: ["bootstrap"],
    languagesIcons: ["fab fa-js", "fab fa-bootstrap", "fab fa-css3-alt"],
    source: "https://github.com/zoheebishaq/HarryPotter",
    info: "Projet réalisé à partir d'un cour sur Udemy dans le cadre de l'apprentissage de bootstrap 5.0.",
    login: "aucun",
    mdp: "aucun",
    picture: "./media/projet9.png",
    online: "http://zoheeb-poudlard.zoheeb-ishaque.com/",
  },
  {
    id: 6,
    name: "zoheeb-react-cour.com",
    languages: ["javascript", "react", "bootstrap"],
    languagesIcons: [
      "fab fa-js",
      "fab fa-css3-alt",
      "fab fa-react",
      "fab fa-bootstrap",
    ],
    source: "https://github.com/zoheebishaq/courses",
    info: "Projet réalisé dans le cadre de l'apprentissage du framework react.js. Ce projet consomme une API qui j'ai développé ''zoheeb-cour-api.com''.",
    picture: "./media/projet12.png",
    login: "aucun",
    mdp: "aucun",
    online: "https://zoheeb-react-cour.herokuapp.com/",
  },
];
